<template>
  <div>
    <b-spinner v-if="categories.length === 0" small />
    <span v-else :class="{'text-danger text-bold': !categoryName }">
      {{ categoryName || 'Aucune catégorie' }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: false,
      default: () => []
    },
    categoryId: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    categoryName () {
      const category = this.categories.find((x) => x.id === this.categoryId)

      return category ? category.name : null
    }
  }
}
</script>
