<template>
  <div class="container pt-5">
    <section class="row">
      <div class="col-lg-5 order-2 order-lg-1">
        <div class="detail-carousel mb-1" :class="{'h-100': !productPhotos.length}">
          <div v-if="!productPhotos.length" class="no-image-available">
            <camera-off-icon size="5x" />
            <p class="d-block mt-3">
              Aucune image disponible
            </p>
          </div>
          <div v-else id="detailSlider" class="swiper-container detail-slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <a
                  v-if="currentPhoto"
                  class="btn btn-photoswipe"
                  :data-caption="productName"
                  data-toggle="photoswipe"
                  @click.prevent="zoom(getPhotoUrl(currentPhoto.file))"
                >
                  <maximize-icon size="1.5x" class="custom-class" />
                </a>
                <vue-slick-carousel
                  ref="c1"
                  class="p-1"
                  :as-nav-for="$refs.c2"
                  :focus-on-select="true"
                  @reInit="handleProductReInit"
                >
                  <img
                    v-for="(photo, index) in productPhotos"
                    :key="`product-image-${index}`"
                    class="product_thumb active"
                    :src="getPhotoUrl(photo.file)"
                    alt=""
                  >
                </vue-slick-carousel>
              </div>
            </div>
            <span class="swiper-notification" aria-live="assertive" aria-atomic="true" />
          </div>
        </div>
        <vue-slick-carousel
          v-if="productPhotos.length > 1"
          ref="c2"
          class="thumbnail-carousel"
          :as-nav-for="$refs.c1"
          :slides-to-show="slidesToShow"
          :focus-on-select="true"
          @afterChange="handleProductPhotoAfterChange"
        >
          <img
            v-for="(photo, index) in productPhotos"
            :key="`product-image-${index}`"
            class="product_thumb thumbnail"
            :src="getPhotoUrl(photo.file)"
            alt=""
          >
        </vue-slick-carousel>
      </div>
      <div class="col-lg-5 pl-lg-4 order-1 order-lg-2 offset-md-1">
        <div class="mb-2">
          Vendu par : <strong>{{ product.storeName }}</strong>
        </div>
        <h1 class="mb-4">
          {{ productName }}
        </h1>
        <h3 class="mb-4 product-subtitle">
          {{ product.subtitle }}
        </h3>
        <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
          <ul class="list-inline mb-2 mb-sm-0">
            <li class="list-inline-item h4 font-weight-light mb-0">
              <variant-price-value
                show-discount
                swap
                :price="price"
                :regular-price="regularPrice"
              />
            </li>
          </ul>
        </div>
        <p class="mb-4 text-muted">
          {{ product.shortDescription }}
        </p>
        <div class="product__details__text">
          <div class="product__details__widget">
            <ul class="list-unstyled p-0">
              <li>
                <h6 class="detail-option-heading">
                  Selectionnez :
                </h6>
              </li>
              <li>
                <div v-for="(option, key) in options" :key="`product-options-${key}`" class="mr-3">
                  <product-dropdown :options="option" :title="key" @value-change="handleOptionChange" />
                </div>
              </li>
            </ul>
          </div>
          <div class="product__details__button d-flex flex-row">
            <div class="quantity">
              <quantity-input
                class="form-control form-control-lg detail-quantity no-border-radius"
                :max="maxQuantityLimit"
                :value="quantityValue"
                @value-change="handleQuantityChange"
              />
            </div>
            <div class="d-flex flex-row align-items-start w-100">
              <button
                class="btn btn-dark btn-block add-to-cart-btn no-border-radius"
                :class="{disable: savingDisabled}"
              >
                <shopping-cart-icon size="1.5x" class="cart-icon mr-3" /> Ajouter au panier
              </button>
            </div>
          </div>
          <div v-if="!!hasSelectedOptions || !isSelectedVariantInStock" class="d-flex align-items-center mt-4">
            Disponibilité : &nbsp;
            <div class="stock_checkbox">
              <div class="text-success">
                <span v-if="isAllVariantSelected && !hasSelectedOptions" class="text-danger">
                  (non disponible dans ces options)
                </span>
                <span v-else-if="!isSelectedVariantInStock" class="text-danger">En rupture de stock</span>
                <span v-else>En stock <i class="fas fa-check-square" /></span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-sm-row py-4 px-2">
            <a
              href="#"
              class="add-to-fav"
              :class="{disable: isWishlistDisabled}"
              @click.prevent
            >
              <heart-icon size="1.5x" class="mr-2" /> Ajouter à ma liste de souhaits
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="row">
      <product-tabs :description="product.description" :specifications="selectedVariant" />
    </section>
  </div>
</template>

<script>
import { HeartIcon, CameraOffIcon, MaximizeIcon, ShoppingCartIcon } from 'vue-feather-icons'
import VueSlickCarousel from 'vue-slick-carousel'
import { isEqual, orderBy } from 'lodash-es'
import { getStorageFileSource } from '@/utils/files'
import ProductTabs from '@/components/preview/ProductTabs.vue'
import QuantityInput from '@/components/preview/QuantityInput.vue'
import ProductDropdown from '@/components/preview/ProductDropdown.vue'
import VariantPriceValue from '@/components/preview/VariantPriceValue.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { removeHTMLTagsFromString } from '@/utils/regex'

export default {
  components: {
    CameraOffIcon,
    HeartIcon,
    MaximizeIcon,
    ProductTabs,
    ProductDropdown,
    QuantityInput,
    ShoppingCartIcon,
    VariantPriceValue,
    VueSlickCarousel
  },
  watch: {
    product: {
      handler () {
        this.$nextTick(() => {
          this.optionsHasChanged = true
          this.handleProductReInit()
        })
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {
        return {
          defaultPrice: 0,
          defaultRegularPrice: 0,
          variants: [],
          productPhotos: []
        }
      }
    }
  },
  data () {
    return {
      quantityValue: 1,
      maxQuantityLimit: 1,
      selectedOptions: {},
      isWishlistDisabled: true,
      savingDisabled: true,
      saving: false,
      currentPhoto: null,
      optionsHasChanged: true,
      showAlert: false
    }
  },
  computed: {
    price () {
      return this.getPrice(this.product.defaultPrice, this.selectedVariantPrices.price)
    },
    regularPrice () {
      return this.getPrice(this.product.defaultRegularPrice, this.selectedVariantPrices.regularPrice)
    },
    hasSelectedOptions () {
      return this.product.variants.some(variant => !variant.options || (isEqual(variant.options, this.selectedOptions)))
    },
    selectedVariant () {
      return this.product.variants.find((variant) => isEqual(variant.options, this.selectedOptions))
    },
    isAllVariantSelected () {
      return Object.keys(this.selectedOptions).length === Object.keys(this.options).length
    },
    isSelectedVariantInStock () {
      return (
        !this.isAllVariantSelected ||
        (this.selectedVariant && this.isVariantInStock(this.selectedVariant))
      )
    },
    selectedVariantPrices () {
      return !this.selectedVariant
        ? {
          price: this.product.defaultPrice,
          regularPrice: this.product.defaultRegularPrice
        }
        : {
          price: this.selectedVariant.price,
          regularPrice: this.selectedVariant.regularPrice
        }
    },
    options () {
      let list = {}
      for (const variant of this.product.variants) {
        if (!variant.options) {
          continue
        }

        Object.entries(variant.options).forEach((entry) => {
          const [key, value] = entry

          if (!list[key]) {
            list = { ...list, [key]: [{ value, text: value }] }
            return
          }

          if (list[key]?.every(item => item.value !== value)) {
            list = { ...list, [key]: [...list[key], { value, text: value }] }
          }
        })
      }

      return list
    },
    productPhotos () {
      const photos = this.product.type === 'simple'
        ? orderBy(this.product.productPhotos, ['rank'], 'asc')
        : orderBy(this.product.productPhotos.filter(photo => (
          this.selectedVariant
            ? photo.productVariantPhotos.some(vp => vp.variantId === this.selectedVariant.id)
            : photo.isGlobal
        )), ['rank', 'isGlobal'], 'asc')

      if (photos.length > 0) {
        return orderBy(photos, photo =>
          this.selectedVariant && photo.productVariantPhotos.some(vp => vp.isDefault && vp.variantId === this.selectedVariant.id), 'desc'
        )
      }

      // only show first element for products with variants but no global images
      if (this.product.productPhotos.length > 0 && this.isSelectedVariantInStock) {
        const [firstPhoto] = orderBy(this.product.productPhotos, ['rank'], 'asc')
        return [firstPhoto]
      }

      return []
    },
    slidesToShow () {
      // ugly hack to get carousel working https://github.com/gs-shop/vue-slick-carousel/issues/153
      return this.productPhotos.length < 4 ? this.productPhotos.length : 4
    },
    productName () {
      return removeHTMLTagsFromString(this.product.name)
    }
  },
  methods: {
    zoom (currentUrl = null) {
      const sources = []

      for (const photo of this.productPhotos) {
        sources.push(this.getPhotoUrl(photo.file))
      }

      const slide = currentUrl ? (sources.indexOf(currentUrl)) : 0

      this.$zoomImage.open(sources, slide)
    },
    handleOptionChange (option) {
      const newOptions = { ...this.selectedOptions, ...option }
      this.optionsHasChanged = !isEqual(this.selectedOptions, newOptions)
      this.selectedOptions = newOptions
    },
    handleQuantityChange (value) {
      this.quantityValue = value
    },
    getPrice (productPrice, variantPrice) {
      return !this.hasSelectedOptions || (this.selectedVariant && this.selectedVariant.pricePolicy === 'default_price')
        ? productPrice
        : variantPrice
    },
    isVariantInStock (variant) {
      if (variant.inventoryMode === 'status') {
        return variant.inventoryStatus === 'in_stock'
      }

      if (variant.inventoryMode === 'stock') {
        return Boolean(variant.inventoryQuantity)
      }

      return false
    },
    getPhotoUrl (file) {
      if (!file) {
        return ''
      }

      return getStorageFileSource(file)
    },
    handleProductReInit () {
      if (!this.optionsHasChanged) {
        return
      }

      this.currentPhoto = this.productPhotos[0]
      if (this.$refs.c1) { this.$refs.c1.goTo(0) }
      if (this.$refs.c2) { this.$refs.c2.goTo(0) }
      this.optionsHasChanged = false
    },
    handleProductPhotoAfterChange (slideIndex) {
      this.currentPhoto = slideIndex ? this.productPhotos[slideIndex] : this.productPhotos[0]
    }
  }
}

</script>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */
::v-deep .thumbnail-carousel {
  height: auto;
}

::v-deep .product_thumb {
  width: 100%;
  max-width: 100%;
  padding: 4px;
  object-fit: contain;
  object-position: center;
}

::v-deep .product_thumb.thumbnail {
  height: 120px;
  max-height: 120px;
}

::v-deep .product_thumb.active {
  height: 500px;
  max-height: 500px;
}

.container {
  background-color: white;
}

.add-to-cart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  transition: all 0.3s;

  &.disable {
    pointer-events: none;
    opacity: 0.3;
  }
}
.stock_checkbox {
  color: #adb5bd;
  font-family: "vremenagrotesk",sans-serif;
  font-size: .8rem;
  font-weight: normal;
}
.no-image-available {
  color: #757575;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.product-subtitle {
  font-size: 1rem;
}
@media (max-width: 767px) {
  #cartAlert {
    .close-absolute.close-centered {
      top: 0;
      right: 0;
      transform: scale(0.5);
    }
  }
}

.detail-background {
    padding-top: 140px;
    padding-bottom: 60px;
}

.detail-carousel {
    position: relative;
}

.btn.btn-photoswipe {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 3rem;
    height: 3rem;
    padding: 0;
    text-align: center;
    letter-spacing: 0;
    border-radius: 50%;
    background: #fff;
    line-height: 3rem;
    z-index: 10;
}

.detail-option-heading {
    span {
        color: #adb5bd;
        font-family: 'vremenagrotesk', sans-serif;
        font-size: .8rem;
        font-weight: normal;
    }
}

.detail-option-btn-label {
    position: relative;
    cursor: pointer;

    &.active {
        box-shadow: none !important;
    }
}

.detail-quantity {
    max-width: 5rem;
    text-align: center;
}

.detail-nav-link.nav-link {
    padding: 1rem 1.5rem;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #6c757d;
    border-color: #fff #fff #e9ecef;
    border-bottom-width: 2px;
    font-size: .8rem;
    font-weight: 700;

    &.active,
    &:hover,
    &:focus {
        color: theme-color('dark');
        border-color: #fff #fff theme-color('dark') !important;
    }
}

.review {
    padding-top: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    border-bottom: 1px solid #e9ecef;

    &:first-of-type {
        padding-top: 1rem;
    }

    &:last-of-type {
        margin-bottom: 0;
        border-bottom: none;
    }
}

.review-image {
    display: block;
    width: 120px;
    max-width: 100%;
    margin: 0 auto .5rem;
    padding: 0.5rem;
    border: solid 1px rgba(#000, .125);
    border-radius: 50%;
    background: #fff;
}

.review-text {
    padding-top: 1rem;
}

.no-border-radius {
  border-radius: 0;
}

::v-deep a {
  color: #757575;
  text-decoration: none;
  background-color: transparent;
}
</style>
