<template>
  <div class="variant-table-root">
    <table class="table table-borderless">
      <thead>
      <tr>
        <th>SKU</th>
        <th v-for="option in options" :key="option">{{ getVariantTypeLabel(option) }}</th>
        <th>Prix</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="variant in variants" :key="variant.key">
        <td>{{ variant.sku || '(auto)' }}</td>
        <td v-for="option in options" :key="option">{{ getOptionValue(variant, option) }}</td>
        <td>{{ variant.pricePolicy === 'custom_price' ? variant.price : 'Défaut' }}</td>
        <td class="text-right">
          <button class="btn btn-sm btn-outline-secondary" @click="handleEditButton(variant)">Editer</button>
        </td>
      </tr>
      </tbody>
    </table>

    <div id="add-variant-btn-container" class="d-inline-block" tabindex="0">
      <button class="btn btn-outline-secondary"
              :disabled="!hasOptions"
              @click="handleAddButton">
        Ajouter une variante
      </button>
    </div>
    <b-tooltip variant="primary" :disabled="hasOptions" target="add-variant-btn-container">
      Vous devez configurer les options du produit avant de créer des variantes
    </b-tooltip>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import useVariantTypes from '@/mixins/useVariantTypes'

// TODO - empty state (when there is no variant)
// TODO - format price correctly

export default {
  props: {
    variants: {
      type: Array,
      default () {
        return []
      }
    },
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  mixins: [useVariantTypes],
  computed: {
    hasOptions () {
      return Object.values(this.options).length > 0
    }
  },
  methods: {
    getOptionValue (variant, option) {
      return get(variant, `options.${option}`)
    },
    handleAddButton () {
      this.$emit('add')
    },
    handleEditButton (variant) {
      this.$emit('edit', variant)
    }
  }
}
</script>
