<template>
  <div class="price-form">
    <v-dynamic-form
      :fields="fields"
      :initial-values="initialValues"
      @change="handleChange"
      :errors="formErrors"
    ></v-dynamic-form>
  </div>
</template>

<script>
import handleForm from '@/mixins/handle-form'

export default {
  mixins: [handleForm],
  props: {
    showTaxes: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'price',
          label: 'Prix de vente',
          type: 'number', // TODO: replace by a currency input
          required: true
        },
        {
          name: 'regularPrice',
          label: 'Prix régulier',
          labelSecondary: "Si l'article est en spécial",
          type: 'number' // TODO: replace by a currency input
        },
        {
          name: 'taxes',
          label: 'Taxes applicables',
          type: 'taxes-selector',
          isVisible: () => { return this.showTaxes && this.availableTaxes && this.availableTaxes.length > 0 }
        }
      ]
    },
    availableTaxes () {
      return this.$store.getters['taxRates/getAvailableTaxes']
    },
    validationRules () {
      return {
        price: 'required'
      }
    }
  }
}
</script>

<style lang="scss">
/* Do not scope! */

.price-form {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
