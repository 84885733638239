<template>
  <drawer
    :title="title"
    :open="open"
    position="right"
    @close="handleCancel"
  >
    <div class="drawer-body">
      <div class="variant-editor">
        <div class="row">
          <div class="col-12">

            <div class="card border-0 flat-card ">
              <div class="card-header">Image Principale</div>
              <div class="card-body d-flex mt-2 flex-column align-items-center justify-content-center">
                <file-image v-if="principalProductPhoto || isUploading" :src="getUrl(principalProductPhotoFile)" :loading="isUploading" />
                <file-upload-button v-else variant="square" @click="handleImageUpload"/>
                <button class="btn btn-default text-primary btn-sm shadow-none mt-2" @click="handleImageChange">
                  Remplacer
                </button>
              </div>
            </div>

            <div class="card border-0 flat-card mb-4">
              <div class="card-header">Caractéristiques</div>
              <div class="card-body">
                <option-value-form v-if="Object.values(options).length > 0" :options="options" :initial-values="optionsFormValues" @change="handleOptionsForm" :show-errors="showErrors"></option-value-form>
                <label v-else class="text-muted small font-italic">
                  Vous n'avez pas encore défini d'options pour ce produit.
                </label>
              </div>
            </div>

            <div class="card border-0 flat-card mb-4">
              <div class="card-header">Prix</div>
              <div class="card-body">
                <variant-price-form :initial-values="priceFormValues"
                                    @change="handlePriceForm"
                                    :show-errors="showErrors"
                                    :product-default-price="productDefaultPrice">
                </variant-price-form>
              </div>
            </div>

            <div class="card border-0 flat-card mb-4">
              <div class="card-header">Inventaire</div>
              <div class="card-body">
                <inventory-form :initial-values="inventoryFormValues" @change="handleInventoryValues" :show-errors="showErrors"></inventory-form>
              </div>
            </div>

            <div class="card border-0 flat-card mb-4">
              <div class="card-header">Dimensions de l'emballage</div>
              <div class="card-body">
                <package-form :initial-values="packageForm" @change="handlePackageValues" :show-errors="showErrors"></package-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer-footer">
      <div class="d-flex justify-content-between align-content-end">
        <div>
          <button v-if="isEditing" class="btn btn-default text-danger" @click.prevent="handleDelete">Supprimer</button>
        </div>
        <div>
          <button class="btn btn-primary" @click.prevent="handleSubmit">Soumettre</button>
          <button class="btn btn-link" @click.prevent="handleCancel">Annuler</button>
        </div>
      </div>
    </div>
  </drawer>
</template>

<script>
// TODO: make sure options are defined before allowing user to create / edit variant

import { cloneDeep, isEmpty, get, omit } from 'lodash-es'
import Drawer from '@/components/common/Drawer'
import OptionValueForm from '@/components/products/editors/variable-products/OptionValueForm'
import VariantPriceForm from '@/components/products/editors/variable-products/VariantPriceForm'
import PackageForm from '@/components/products/editors/PackageForm'
import InventoryForm from '@/components/products/editors/InventoryForm'
import FileImage from '@/components/common/FileImage'
import FileUploadButton from '@/components/common/FileUploadButton'
import productImages from '@/mixins/productPhotos'

export default {
  mixins: [productImages],
  components: {
    Drawer,
    OptionValueForm,
    VariantPriceForm,
    PackageForm,
    InventoryForm,
    FileImage,
    FileUploadButton
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    variant: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    productDefaultPrice: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showErrors: false,
      isOptionFormValid: null,
      isPriceFormValid: null,
      isInventoryFormValid: null,
      isPackageFormValid: null,
      formData: {},
      images: []
    }
  },
  computed: {
    isEditing () {
      return Object.values(this.variant).length > 0
    },
    title () {
      return !this.isEditing ? 'Nouvelle variante' : 'Modifier la variante'
    },
    optionsFormValues () {
      const values = {}

      for (const option of this.options) {
        values[option] = get(this.formData, `options.${option}`)
      }

      return values
    },
    priceFormValues () {
      return {
        pricePolicy: get(this.formData, 'pricePolicy', 'default_price'),
        price: get(this.formData, 'price'),
        regularPrice: get(this.formData, 'regularPrice')
      }
    },
    inventoryFormValues () {
      return {
        sku: get(this.formData, 'sku'),
        limitPerOrder: get(this.formData, 'limitPerOrder'),
        finalSale: get(this.formData, 'finalSale'),
        allowBackorders: get(this.formData, 'allowBackorders'),
        lowStockThreshold: get(this.formData, 'lowStockThreshold'),
        stockInventoryMode: get(this.formData, 'stockInventoryMode'),
        statusInventoryMode: get(this.formData, 'statusInventoryMode'),
        inventoryQuantity: get(this.formData, 'inventoryQuantity')
      }
    },
    packageForm () {
      return {
        length: get(this.formData, 'length'),
        width: get(this.formData, 'width'),
        height: get(this.formData, 'height'),
        weight: get(this.formData, 'weight')
      }
    },
    principalProductPhoto () {
      for (const photo of this.images) {
        if (!photo.productVariantPhotos || !photo.productVariantPhotos.length) {
          continue
        }

        const foundItem = photo.productVariantPhotos.find((vp) =>
          vp.isDefault &&
          ((!vp.variantId && !vp.variantKey) ||
          ((vp.variantKey && vp.variantKey === this.variant.key) || (vp.variantId && vp.variantId === this.variant.id)))
        )

        if (!foundItem) {
          continue
        }

        return photo
      }

      return undefined
    },
    principalProductPhotoFile () {
      return this.principalProductPhoto ? this.principalProductPhoto.file : undefined
    }
  },
  methods: {
    handleOptionsForm (values, isValid) {
      this.formData = {
        ...this.formData,
        options: { ...values }
      }

      this.isOptionFormValid = isValid
    },
    handlePriceForm (values, isValid) {
      this.formData = {
        ...this.formData,
        pricePolicy: values.pricePolicy,
        price: values.price ? Number(values.price) : null,
        regularPrice: values.regularPrice ? Number(values.regularPrice) : null
      }

      this.isPriceFormValid = isValid
    },
    handleInventoryValues (values, isValid) {
      this.formData = {
        ...this.formData,
        ...values
      }

      this.isInventoryFormValid = isValid
    },
    handlePackageValues (values, isValid) {
      this.formData = {
        ...this.formData,
        ...values
      }
      this.isPackageFormValid = isValid
    },
    async handleImageUpload (files) {
      const [firstProductPhoto] = await this.handleUpload(files)

      if (!firstProductPhoto) {
        return
      }

      this.images = this.updateImageVariant(firstProductPhoto)
    },
    async handleImageChange () {
      try {
        const productPhoto = await this.$modal.open(
          () => import('@/modals/UploadImageModal.vue'),
          { productImage: this.principalProductPhoto }
        )

        this.images = this.updateImageVariant(productPhoto)
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    updateImageVariant (selectedProductPhoto) {
      // update all existing product images default status for this.variant
      const updatedImages = this.images.map(photo => {
        return {
          ...photo,
          // if the photo has the variant
          productVariantPhotos: photo.productVariantPhotos &&
            photo.productVariantPhotos.some(vp => (this.variant.id && this.variant.id === vp.variantId) || (this.variant.key && this.variant.key === vp.variantKey))
            ? photo.productVariantPhotos.map(vp => {
              // then update the default status
              return {
                ...vp,
                isDefault: this.variant.id === vp.variantId || this.variant.key === vp.variantKey
                  ? photo.fileId === selectedProductPhoto.fileId
                  : vp.isDefault
              }
            })
            // else add only the variant to the selected photo
            : photo.fileId === selectedProductPhoto.fileId
              ? [...(photo.productVariantPhotos || []), {
                productPhotoId: selectedProductPhoto.id,
                variantId: this.variant.id,
                variantKey: this.variant.key,
                isDefault: true
              }]
              : [...(photo.productVariantPhotos || [])]
        }
      })

      const isNewPhoto = this.images.every(photo => photo.fileId !== selectedProductPhoto.fileId)

      if (isNewPhoto) {
        const updatedProductPhoto = {
          ...selectedProductPhoto,
          productVariantPhotos: [
            {
              productPhotoId: selectedProductPhoto.id,
              variantId: this.variant.id,
              variantKey: this.variant.key,
              isDefault: true
            }]
        }

        return [...updatedImages, { ...updatedProductPhoto }]
      }

      return updatedImages
    },
    handleSubmit () {
      this.showErrors = true

      if (this.isOptionFormValid === false || this.isPriceFormValid === false || !this.isInventoryFormValid || !this.isPackageFormValid) {
        return
      }

      this.showErrors = false
      const variant = this.getVariantData()
      this.$emit('submit', { ...variant }, [...this.images])
    },
    getVariantData () {
      const inventoryMode = this.formData.stockInventoryMode ? 'stock' : 'status'
      const inventoryQuantity = this.formData.stockInventoryMode ? this.formData.inventoryQuantity : null
      let inventoryStatus = !!inventoryQuantity && this.formData.stockInventoryMode ? 'in_stock' : 'out_of_stock'

      if (!this.formData.stockInventoryMode && this.formData.statusInventoryMode) {
        inventoryStatus = this.formData.statusInventoryMode ? 'in_stock' : 'out_of_stock'
      }

      return {
        ...omit(this.formData, ['stockInventoryMode', 'statusInventoryMode']),
        inventoryMode,
        inventoryStatus,
        inventoryQuantity
      }
    },
    handleCancel () {
      this.images = cloneDeep(this.productPhotos)
      this.showErrors = false
      this.$emit('cancel')
    },
    handleDelete () {
      if (!this.isEditing) {
        return
      }

      this.showErrors = false
      this.$emit('delete', this.variant.id)
    }
  },
  watch: {
    variant () {
      // if new variant then set the status to true
      const statusInventoryMode = !isEmpty(this.variant)
        ? this.variant.inventoryMode === 'status' && this.variant.inventoryStatus === 'in_stock'
        : true

      this.formData = {
        ...omit(this.variant, ['inventoryMode', 'inventoryStatus']),
        stockInventoryMode: this.variant.inventoryMode === 'stock',
        statusInventoryMode
      }
    },
    open () {
      this.images = this.productPhotos
    }
  }
}
</script>

<style scoped>
</style>
