/**
 * Returns a string stripped from HTML tags
 * @param value
 */
export const removeHTMLTagsFromString = (value: string | undefined | null): string | null | undefined => {
  if (!value?.trim()?.length) {
    return value
  }

  return value.replace(/(<([^>]+)>)/gi, '')
}
