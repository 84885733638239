<template>
  <drawer
    title="Option d'image"
    :open="open"
    position="right"
    @close="$emit('cancel')"
  >
    <div class="drawer-body">
      <div class="row">
        <div class="col-12">
          <div class="image-container">
            <file-image v-if="image" :src="getUrl(image.file)" :loading="isUploading" />
            <file-upload-button variant="btn" size="sm" class="mt-2" :multiple="false" @click="handleImageUpload">
              Remplacer
            </file-upload-button>
          </div>
          <div class="card flat-card border-0 my-4" v-if="productType === 'variable'">
            <div class="card-header">
             Visibilité
            </div>
            <div class="card-body">
              <b-form-checkbox
                v-if="image"
                :checked="image.isGlobal"
                @change="toggleIsGlobalCheck"
                class="mb-3 ml-3"
                switch
              >
                Image principale
                <label class="d-block text-muted small font-italic">
                  Cette image sera visible si aucune variante n'est sélectionnée
                </label>
              </b-form-checkbox>
              <div class="mb-3">Afficher cette image pour les variantes suivantes:</div>
              <label v-if="!variants.length" class="text-muted small font-italic">
                Vous n'avez défini aucune variante pour ce produit.
              </label>
              <b-form-checkbox
                v-else
                v-for="variant in variants"
                :key="variant.id"
                :checked="isVariantChecked(variant)"
                @change="toggleVariantCheck(variant)"
                class="mb-3 ml-3"
                switch
              >
                <div>
                  <div>{{ !Object.values(variant.options).length ? '-' : Object.values(variant.options).sort().join(', ') }}</div>
                  <variant-price-value class="variant-price" :price="getPrice(variant)" :regular-price="getRegularPrice(variant)" />
                </div>
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer-footer">
      <div class="d-flex justify-content-between align-content-end">
        <div>
          <button class="btn btn-default text-danger" @click.prevent="handleDelete">Supprimer</button>
        </div>
        <div>
        <button class="btn btn-primary" @click.prevent="handleSubmit">Enregistrer</button>
        <button class="btn btn-link" @click.prevent="handleCancel">Annuler</button>
        </div>
      </div>
    </div>
  </drawer>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import Drawer from '@/components/common/Drawer'
import FileImage from '@/components/common/FileImage'
import FileUploadButton from '@/components/common/FileUploadButton'
import VariantPriceValue from '@/components/common/VariantPriceValue'
import productImages from '@/mixins/productPhotos'

export default {
  mixins: [productImages],
  components: {
    Drawer,
    FileImage,
    FileUploadButton,
    VariantPriceValue
  },
  data () {
    return {
      image: cloneDeep(this.selectedImage)
    }
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    productType: {
      type: String,
      required: true
    },
    productDefaultPrice: {
      type: Number,
      required: true
    },
    productDefaultRegularPrice: {
      type: Number,
      required: true
    },
    selectedImage: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    variants: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  methods: {
    async handleImageUpload (files) {
      const [firstImage] = await this.handleUpload(files)
      this.image = {
        ...firstImage,
        isGlobal: this.image.isGlobal,
        productVariantPhotos: !this.image.productVariantPhotos
          ? null
          : this.image.productVariantPhotos.map(vp => ({ ...vp, productId: null }))
      }
    },
    handleDelete () {
      this.$emit('delete', this.image.file.id)
    },
    handleCancel () {
      this.$emit('cancel')
    },
    handleSubmit () {
      this.$emit('submit', this.image)
    },
    isVariantChecked (variant) {
      if (!this.image || !this.image.productVariantPhotos) {
        return false
      }

      return this.image.productVariantPhotos.some(vp => vp.variantId === variant.id || vp.variantKey === variant.key)
    },
    toggleIsGlobalCheck () {
      this.image = { ...this.image, isGlobal: !this.image.isGlobal }
    },
    toggleVariantCheck (variant) {
      if (!this.isVariantChecked(variant)) {
        this.image.productVariantPhotos =
          [
            ...(this.image.productVariantPhotos || []),
            { isDefault: false, productPhotoId: this.image.id, variantId: variant.id, variantKey: variant.key }
          ]
      } else {
        this.image.productVariantPhotos = this.image.productVariantPhotos &&
          this.image.productVariantPhotos.filter(vp => vp.variantId !== variant.id || (!vp.variantId && vp.variantKey !== variant.key))
      }
    },
    getPrice (variant) {
      return this.getPriceByVariantPolicy(this.productDefaultPrice, variant.price, variant.pricePolicy)
    },
    getRegularPrice (variant) {
      return this.getPriceByVariantPolicy(this.productDefaultRegularPrice, variant.regularPrice, variant.pricePolicy)
    },
    getPriceByVariantPolicy (productPrice, variantPrice, variantPolicy) {
      return variantPolicy === 'default_price' ? productPrice : variantPrice
    }
  },
  watch: {
    selectedImage (image) {
      if (!image) {
        return
      }

      this.image = cloneDeep(image)
    },
    open (isOpen) {
      if (!isOpen) {
        this.image = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}
.variant-price {
  opacity: 0.7;
  font-size: 0.75em;
}
</style>
