<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between flex-column flex-sm-row mb-5">
        <h2 class="mb-4 mb-sm-0">Produits</h2>

        <div class="w-100 d-flex align-items-center justify-content-end flex-wrap-reverse">
          <button class="btn btn-outline-primary mr-2" @click="$router.push({ name: 'products.import' })" :disabled="isSuspended">Import CSV</button>

          <b-dropdown id="dropdown-create-product" variant="primary" text="Ajouter un produit" :disabled="isSuspended">
            <b-dropdown-item @click="$router.push({ name: 'products.create', params: { type: 'simple' } })">Produit simple</b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'products.create', params: { type: 'variable' } })">Produit avec variantes</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="products"
          placeholder-text-filter="Recherche par nom ou SKU"
          :display-text-filter="true"
          :display-type-filter="true"
          :list-type-filter="getTypeList"
          :display-status-filter="true"
          :list-status-filter="getStatusList"
          :display-reset="true"
          @changePage="changePage"
        />

        <b-collapse v-model="showActionBar">
          <div class="mb-4">
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-between">
                  <div>
                    <div class="form-inline">
                      <div class="form-group mt-1 mobile-wid-100">
                        Sélection: {{ rows.length }} élement(s)
                      </div>
                      <div class="ml-4 hidden-md"></div>
                      <div class="d-flex align-items-baseline justify-content-center justify-content-md-start mt-1 mobile-wid-100">
                        <button class="btn btn-sm btn-outline-primary mr-2" @click="handleChangeCategory">
                          <fa-icon class="on-open mr-1" :icon="['fas', 'tshirt']" size="sm"></fa-icon>
                          Modifier la catégorie
                        </button>
                        <button class="btn btn-sm btn-outline-primary mr-2" @click="handleChangeStatus">
                          <fa-icon class="on-open mr-1" :icon="['fas', 'eye']" size="sm"></fa-icon>
                          Modifier le statut
                        </button>
                        <button class="btn btn-sm btn-outline-primary mr-2" @click="handleBulkDelete">
                          <fa-icon class="on-open mr-1" :icon="['fas', 'trash']" size="sm"></fa-icon>
                          Supprimer le produit
                        </button>
                      </div>
                      <div class="ml-2 hidden-md"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="products"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              :default-actions="false"
              :custom-actions="getActions"
              no-top-border
              hover
              :link="(item) => `/products/edit/${item.id}`"
              @edit="(item) => $router.push({ name: 'products.edit', params: { id: item.id } })"
              @delete="handleDelete"
              @draft="eventDraft"
              @published="eventPublished"
              @archived="eventArchived"
              :empty-img-src="require('@/assets/images/icons/product-2.svg')"
              empty-message="Vos produits s'afficheront ici"
              @sortBy="handleSortBy"
            >
              <template v-slot:selectRow>
                <checkbox-cell :checked="headerChecked" id="header" :indeterminate="headerIndeterminate" :clickEvent="getAllRow" />
              </template>
            </collection-table>

            <div v-if="paginatedData.data.length > 0" class="drawer-footer">
              <pagination state="products"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :display-per-page="true"
                          :range="2"
                          @change="changePage"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import userData from '@/mixins/user-data'
import formatsCurrency from '@/mixins/formatsCurrency'
import selectableRow from '@/mixins/selectableRow'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import CategoryItemCell from '@/components/categories/CategoryItemCell'
import CheckboxCell from '@/components/common/CheckboxCell'
import Thumbnail from '@/components/common/Thumbnail'
import { getStorageFileSource } from '@/utils/files'
import pagination from '@/mixins/pagination'
import { removeHTMLTagsFromString } from '@/utils/regex'

export default {
  components: {
    BCollapse,
    CheckboxCell,
    Pagination,
    PaginationFilter
  },
  mixins: [userData, formatsCurrency, pagination, selectableRow],
  data () {
    return {
      showActionBar: false
    }
  },
  watch: {
    rows: {
      handler (rows) {
        this.showActionBar = rows.length > 0

        if (rows.length > 0 && rows.length !== this.perPage) {
          this.headerIndeterminate = true
          this.headerChecked = false
        } else if (rows.length > 0 && rows.length === this.perPage) {
          this.headerIndeterminate = false
          this.headerChecked = true
        } else {
          this.headerIndeterminate = false
          this.headerChecked = false
        }
      },
      immediate: true
    }
  },
  computed: {
    getStatusList () {
      return [
        {
          value: 'draft',
          label: 'Brouillon'
        },
        {
          value: 'published',
          label: 'Publiés'
        },
        {
          value: 'archived',
          label: 'Archivés'
        }
      ]
    },
    getTypeList () {
      return [
        {
          value: 'simple',
          label: 'Produits simples'
        },
        {
          value: 'variable',
          label: 'Produits avec variantes'
        }
      ]
    },
    cols () {
      return [
        {
          header: '',
          headerCheckbox: true,
          property: 'id',
          component: CheckboxCell,
          props: (item) => {
            return {
              id: item.id,
              checked: this.isInRows(item.id),
              clickEvent: this.rowClickEvent
            }
          }
        },
        {
          header: 'Aperçu',
          property: 'productPhotos',
          component: Thumbnail,
          props: (item) => {
            const photo = item.productPhotos?.find(photo => photo.isGlobal)
            return {
              src: photo?.file ? getStorageFileSource(photo.file) : undefined,
              alt: item.name
            }
          }
        },
        {
          header: 'Nom du produit',
          property: 'name',
          isSortable: true,
          method: (product) => {
            return removeHTMLTagsFromString(product.name)
          }
        },
        {
          header: 'Catégorie',
          property: 'categoryId',
          component: CategoryItemCell,
          props: (item) => {
            return {
              categoryId: item.categoryId,
              categories: this.productCategories
            }
          }
        },
        {
          header: 'Prix par défaut',
          property: 'defaultPrice',
          method: (product) => {
            return !product.defaultPrice
              ? this.$money(product.defaultRegularPrice)
              : this.$money(product.defaultPrice)
          },
          isSortable: true
        },
        {
          header: 'Type',
          property: 'type',
          method: (product) => {
            return product.type === 'simple' ? 'Simple' : 'Variantes'
          },
          isSortable: true
        },
        {
          header: 'Variantes',
          property: 'variants',
          method: (product) => {
            if (product.type === 'simple') {
              return '-'
            }

            return product.variants.length
          }
        },
        {
          header: 'Statut',
          property: 'status',
          method: (product) => {
            switch (product.status) {
              case 'published':
                return 'Publié'
              case 'archived':
                return 'Archivé'
              default:
                return 'Brouillon'
            }
          },
          isSortable: true
        }
      ]
    },
    products () {
      return this.paginatedData.data
    },
    productCategories () {
      return this.$store.state.productCategories.collection
    },
    isSuspended () {
      return this.$store.getters.isSuspended
    }
  },
  methods: {
    getAllRow (checked) {
      if (this.headerIndeterminate) {
        this.headerChecked = false
        this.headerIndeterminate = false
        this.resetRows()
      } else {
        this.headerChecked = checked

        if (checked) {
          const rows = this.products.map((product) => {
            return product.id
          })
          this.setRows(rows)
        } else {
          this.resetRows()
        }
      }
    },
    getActions (item) {
      const actions = []

      const draft = {
        label: 'Brouillon',
        icon: '',
        event: 'draft',
        index: 10
      }

      const published = {
        label: 'Publier',
        icon: '',
        event: 'published',
        index: 10
      }

      const archived = {
        label: 'Archiver',
        icon: '',
        event: 'archived',
        index: 10
      }

      switch (item.status) {
        case 'draft':
          actions.push(published)
          break
        case 'published':
          actions.push(draft)
          actions.push(archived)
          break
        case 'archived':
          actions.push(draft)
          break
      }

      const defaultActions = [
        {
          label: 'Editer',
          icon: '',
          event: 'edit',
          index: 10
        },
        {
          label: 'Supprimer',
          icon: '',
          event: 'delete',
          index: 10
        }
      ]

      return [...actions, ...defaultActions]
    },
    async handleChangeCategory () {
      try {
        await this.$modal.openFormModal({
          title: `Éditer la catégorie pour ${this.rows.length} produit(s)`,
          fields: [
            {
              name: 'categoryId',
              label: 'Catégorie',
              type: 'product-category-selector',
              required: true
            }
          ],
          initialValues: { categoryId: '' },
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
          onSubmit: async ({ values, confirm, setErrors }) => {
            setErrors({})

            if (!values.categoryId) {
              setErrors({
                categoryId: ['Ce champ est requis.']
              })
              return
            }

            await this.updateProducts({ categoryId: values.categoryId })

            confirm()
          }
        })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async handleChangeStatus () {
      try {
        await this.$modal.openFormModal({
          title: `Éditer le statut pour ${this.rows.length} produit(s)`,
          fields: [
            {
              name: 'status',
              label: 'Statut',
              type: 'select',
              options: [
                {
                  value: 'draft',
                  label: 'Brouillon'
                },
                {
                  value: 'published',
                  label: 'Publié'
                },
                {
                  value: 'archived',
                  label: 'Archivé'
                }
              ],
              required: true
            }
          ],
          initialValues: { status: '' },
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
          onSubmit: async ({ values, confirm, setErrors }) => {
            setErrors({})

            if (!values.status) {
              setErrors({
                status: ['Ce champ est requis.']
              })
              return
            }

            await this.updateProducts({ status: values.status })

            confirm()
          }
        })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async updateProducts ({ categoryId, status }) {
      await this.$store.dispatch(
        'products/patchProducts',
        { products: this.rows, categoryId, status, storeId: this.currentStoreId }
      )
      this.rows = []

      this.$toasted.success('Produit(s) mis à jour avec succès.')
    },
    async updateStatus (id, status) {
      await this.$store.dispatch('products/updateStatus', { id, status, storeId: this.currentStoreId })
    },
    async eventDraft (item) {
      await this.updateStatus(item.id, 'draft')
    },
    async eventPublished (item) {
      await this.updateStatus(item.id, 'published')
    },
    async eventArchived (item) {
      await this.updateStatus(item.id, 'archived')
    },
    async handleBulkDelete () {
      try {
        await this.$modal.openConfirmModal({
          title: `Supprimer ${this.rows.length} produit(s)`,
          message: `Veuillez confirmer que vous voulez supprimer ${this.rows.length} produit(s).`,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$store.dispatch('products/deleteProducts', { storeId: this.currentStoreId, products: this.rows })
        this.rows = []
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async handleDelete (item) {
      try {
        await this.$modal.openConfirmModal({
          title: 'Supprimer le produit',
          message: 'Veuillez confirmer que vous voulez supprimer ce produit.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$store.dispatch('products/delete', { storeId: this.currentStoreId, id: item.id })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async changePage (page) {
      await this.fetchProducts(page)
    },
    async fetchProducts (page) {
      await this.$store.dispatch('products/fetch', { page, storeId: this.currentStoreId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchProducts()
    }
  },
  async created () {
    this.instance = 'products'

    if (this.$route.query.variantId) {
      this.textFilter = this.$route.query.variantId
    }

    await this.fetchProducts()
    await this.$store.dispatch('productCategories/fetchOrRefresh')
  }
}
</script>

<style lang="scss" scoped>
.container-fluid >>> .variants-col-width {
  max-width: 60px
}
</style>
