<template>
  <div class="type-select" v-click-outside="handleClickOutside">
    <div class="overlay" v-if="isOpen" @click="handleClickOutside"></div>

    <div id="add-option-btn-container" class="d-inline-block" tabindex="0">
      <button class="btn btn-outline-secondary" :disabled="disabled" @click="open">
        Ajouter une option
      </button>
    </div>
    <b-tooltip variant="primary" :disabled="!disabled" target="add-option-btn-container">
      Le nombre d'options est limité à 3 par produit avec variantes
    </b-tooltip>

    <div class="type-select-panel" v-if="isOpen">
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <div class="search-input">
            <span class="icon">
              <fa-icon :icon="['fas', 'search']"></fa-icon>
            </span>
            <input type="text" class="form-control" placeholder="Rechercher par nom..." v-model="searchTerm">
          </div>
          <div v-if="isSearchTermDefined" class="mt-2">
            <a href="" class="clear-search" @click.prevent="resetSearch">
              Réinitialiser la recherche
            </a>
          </div>
        </div>
        <template v-if="!isSearchTermDefined">
          <div class="option-list">
            <template v-for="type in filteredVariantTypes">
              <a :key="type.id" href="" class="list-group-item list-group-item-action" @click.prevent="handleSelect(type)">
                <div>{{ type.label }}</div>
              </a>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="option-list">
            <template v-for="type in getSearchValues(searchTerm)">
              <a :key="type.id" href="" class="list-group-item list-group-item-action" @click.prevent="handleSelect(type)">
                <div>{{ type.label }}</div>
              </a>
            </template>
          </div>
        </template>

        <div class="list-group-item">
          <button class="btn btn-link btn-sm mb-2 px-0 shadow-none" @click.prevent="handleNewOptionToggle">
            Autre...
          </button>
          <div v-if="showAddInput" class="form-group input-group-sm">
            <input type="text"
                   class="form-control add-label-input"
                   :value="newLabelText"
                   :class="{ 'is-invalid': hasError }"
                   @input="handleLabelChange"
                   placeholder="Ajouter une nouvelle option">
            <span v-if="hasError" class="d-block small text-danger">
              Cette option existe déjà dans la liste
            </span>
            <button class="btn btn-primary btn-sm mt-2" :disabled="!canAdd" @click.prevent="handleAddNewLabel">
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deburr, filter, orderBy, take } from 'lodash-es'

export default {
  props: {
    variantTypes: {
      type: Array,
      required: true,
      default: () => []
    },
    initialOptions: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      isOpen: false,
      searchTerm: '',
      newLabelText: '',
      showAddInput: false,
      canAdd: false,
      hasError: false
    }
  },
  computed: {
    filteredVariantTypes () {
      return orderBy(this.variantTypes.filter(type => !this.initialOptions.includes(type.key)), ['label'], ['asc'])
    },
    isSearchTermDefined () {
      return this.searchTerm && this.searchTerm.length > 0
    }
  },
  methods: {
    handleSelect (type) {
      this.$emit('select', type.key)
      this.isOpen = false
      this.newLabelText = ''
      this.searchTerm = ''
      this.hasError = false
      this.showAddInput = false
    },
    handleClickOutside () {
      this.isOpen = false
      this.newLabelText = ''
      this.hasError = false
      this.showAddInput = false
    },
    handleLabelChange (event) {
      const value = event.target.value
      this.canAdd = value && value.length > 0
      this.newLabelText = value
      this.hasError = false
    },
    handleAddNewLabel () {
      this.hasError = this.getSearchValues(this.newLabelText).length > 0

      if (this.hasError) {
        return
      }

      this.$emit('add', this.newLabelText)
      this.searchTerm = ''
      this.isOpen = false
      this.showAddInput = false
    },
    handleNewOptionToggle () {
      this.showAddInput = !this.showAddInput
    },
    open () {
      this.isOpen = true
    },
    resetSearch () {
      this.searchTerm = ''
    },
    getSearchValues (search) {
      return take(filter(this.variantTypes, (type) => {
        return type.label && deburr(type.label).toLowerCase().includes(deburr(search).toLowerCase())
      }), 15)
    }
  }
}
</script>

<style lang="scss" scoped>
.type-select {
  position: relative;
  width: 100%;
  min-width: 260px;
  max-width: 260px;

  .type-input {
    background-color: #fff;
  }

  .type-select-panel {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.2), 0 2px 3px 0 rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 4px;
    background: white;
    z-index: 110;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.search-input {
  position: relative;

  .form-control {
    padding-left: 35px;
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 0;
    line-height: 38px;
    height: 38px;
    opacity: 0.7;
  }
}

.clear-search {
  font-size: 13px;
}

.option-list {
  max-height: 360px;
  overflow-y: auto;

  .list-group-item-action {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.add-label-input::-webkit-input-placeholder {
  font-size: 0.9rem;
}
</style>
